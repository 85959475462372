
import './styles.scss'

import dragonCraftLogo from "./dragoncraft.png";
import dontSwear from "./dontSwear.png";
import spawn from "./spawn.png";

export default function Home() {
  return (
    <main>
      <div className="title">
        <h1 className="main-title">DragonCraft</h1>
        <h3 className="sub-title">Reunion page</h3>
      </div>
      
      <p className="sparklez">In a world... where we're back... with another round of 2015!!!</p>
      <img src={dragonCraftLogo} alt="DragonCraft Logo" className="DC-logo"/>
      <p className="subtext">DragonCraft's spawn - An old 1.5.2 server</p>

      <p className="greeting">Hello fellow DragonCraft member! Long time no see! 
        <br/>I am most happy that you managed to find this page, and would love to 
        chat with my old friend again, <br/>even if we didn't have many interactions, it'd be amazing if we could just say hi to each other.
        <br/>Message me on mail, I'll check it often! <span className='my-mail'>cookiecakedragoncraft@gmail.com</span> <span className='mail-note'>cookiecakeyummy was taken 😔</span>
        <br/>Alternatively add me on Discord :x <span className="discord">pavle000</span>
      </p>

      <img src={dontSwear} alt="Some text of mine" className="dont-swear"/>

      <p className="discord-info">
        Unfortunately, Enjin, the forum we used got shut down. 
        <br/>Even while it was up it took effort to find the link to the DragonCraft discord that exists since 2019.
        <br/>Here's the link (I'll try to remember to update it): <a href="https://discord.gg/rRMnu2JrDJ" target="_blank" className="discord-link">https://discord.gg/rRMnu2JrDJ</a>
        <br/> I can't wait to talk to you! :D
      </p>

      <img src={spawn} alt="DragonCraft Spawn" className="dont-swear"/>

      <div className='minecraft-end-text'>
        <p>"and sometimes the player believed the universe had spoken to it through the zeros and ones, through the electricity of the world,</p> 
        <p>through the scrolling words on a screen at the end of a dream</p>
        <p>and the universe said I love you</p>
        <p>and the universe said you have played the game well</p>
        <p>and the universe said everything you need is within you</p>
        <p>and the universe said you are stronger than you know</p>
        <p>and the universe said you are the daylight</p>
        <p>and the universe said you are the night</p>
        <p>and the universe said the darkness you fight is within you</p>
        <p>and the universe said the light you seek is within you</p>
        <p>and the universe said you are not alone</p>
        <p>and the universe said you are not separate from every other thing</p>
        <p>and the universe said you are the universe tasting itself, talking to itself, reading its own code</p>
        <p className='break-after'>and the universe said I love you because you are love.</p>
        
        <p>And the game was over</p>
        <p>and the player woke up from the dream.</p> 
        <p>And the player began a new dream.</p> 
        <p>And the player dreamed again, dreamed better.</p> 
        <p>And the player was the universe.</p> 
        <p className='margin-after'>And the player was love."</p>
      </div>

      <p className="credits">Screenshots provided by Archive 🙏</p>
    </main>
  )
} 
